import React from 'react'
import { styles } from '../assets/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Stacks = ({name, icon}) => {
  return (
    <li className={styles.iconText} ><FontAwesomeIcon className={styles.icon} icon={icon}/>{name}</li>
  )
}

export default Stacks