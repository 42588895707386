import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX } from '@fortawesome/free-solid-svg-icons'
import {React, useState, useEffect, useRef } from 'react'

const NavBar = ({changeLang, changeDarkMode, darkMode, lang, toggleMenu, menuActive}) => {

    const [height, setHeight] = useState(0)
    const wrapperRef = useRef(null)
    useOutsideAlerter(menuActive ? wrapperRef : '' )

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              toggleMenu()
              setHeight(0)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      

    function showMenu() {
        setHeight(height === 0 ? 360 : 0)
        toggleMenu()
    }

    return (

      <div ref={wrapperRef} className='bg-black/30 relative w-full h-[60px] xl:h-[80px] mini:text-sm xs:text-base sm:text-lg xl:text-xl flex justify-between items-center px-4 lg:rounded-b-xl z-30'>

          <span className='text-yellow-500'>{lang.dev.name}</span> 
          
          <ul className='hidden sm:flex justify-center items-center'>
              

              {lang.links.map( (link, idx) => {
                  return <a key={idx} href={`#${link}`} className='text-white mr-4 cursor-pointer hover:underline'>{link}</a>
              })}

              <i className='w-2px h-[20px] border border-solid border-yellow-500'></i>

              {/* <button className='text-yellow-500 mx-4 cursor-pointer hover:underline'>Resume</button> */}
              
              {/* <i className='w-2px h-[20px] border border-solid border-yellow-500'></i> */}

              <button onClick={() => changeLang(true)} className=' text-white mx-3 cursor-pointer hover:underline'>English</button>
              <button onClick={() => changeLang(false)} className='text-white mr-4 cursor-pointer hover:underline'>Spanish</button>
          </ul>

          {/* Mobile */}

          <ul className='sm:hidden flex justify-center items-center'>

            <button onClick={() => showMenu()}>
                    <FontAwesomeIcon className='text-white text-[30px]' icon={menuActive ? faX : faBars}/>
            </button>
          </ul>

          <div className={`absolute md:hidden bg-slate-800 h-[${height}px] w-[150px] z-20 right-0 top-[60px] transition-all`}>
              <ul className={`${height === 0 ? 'hidden' : 'flex'} flex-col justify-between bg-black/30`}>
              {lang.links.map( (link, idx) => {
                  return <a key={idx} onClick={() => showMenu()} href={`#${link}`} className={`text-white h-[60px] flex justify-center items-center ${idx ? 'border-t-[1px] border-solid border-gray-700' : ''} mx-5`}>{link}</a>
              })}

              <button onClick={() => changeLang(true)} className='text-yellow-500 h-[60px] flex justify-center items-center border-t-[1px] border-solid border-gray-700'>English</button>
              <button onClick={() => changeLang(false)} className='text-yellow-500 h-[60px] flex justify-center items-center border-t-[1px] border-solid border-gray-700 mx-5'>Spanish</button>
              
              {/* <i className='text-yellow-500 h-[60px] flex justify-center items-center border-t-[1px] border-solid border-gray-700'>Resume</i> */}

              </ul>
          </div>


          {/* <ul className='sm:hidden flex justify-center items-center'>
          {darkMode ? 

                <button 
                className='sm:hidden dark:border-white p-2 rounded-full z-10'
                onClick={() => changeDarkMode()}
                >
                    <FontAwesomeIcon className='text-[22px] text-yellow-500 mr-3 hover:text-white' icon={faSun}/>
                </button> 

                :

                <button 
                className='sm:hidde dark:border-white p-2 rounded-full z-10'
                onClick={() => changeDarkMode()}
                >
                    <FontAwesomeIcon className='text-[22px] text-yellow-500 mr-3 hover:text-white' icon={faMoon}/>
                </button> 
}  
            <button>
                <FontAwesomeIcon className='text-yellow-500 text-[22px] hover:text-white' icon={faBars}/>
            </button>
          </ul> */}

        {/* Dark mode for bigger screens */}

        {/* {darkMode ? 

            <button 
            className='hidden sm:block absolute right-10 top-[80px] border-2 border-dotted border-white p-2 rounded-full hover:scale-[.9] z-10'
            onClick={() => changeDarkMode()}
            >
                <FontAwesomeIcon className='text-[25px] text-yellow-500' icon={faSun}/>
            </button> 
        
        :
        
            <button 
            className='hidden sm:block absolute right-10 top-[80px] dark:border-dotted border-white p-2 rounded-full hover:scale-[.9] z-10'
            onClick={() => changeDarkMode()}
            >
                <FontAwesomeIcon className='text-[25px] text-yellow-500' icon={faMoon}/>
            </button> 
        } */}

      </div>
    )
}

export default NavBar