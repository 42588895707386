import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {languages} from '../assets/content.js'

const WeatherDate = ({langContent, weather, icon}) => {


    function getDate() {
        let date = new Date()
        let pstDate = date.toLocaleString('en-US', {
          timeZone: "America/Los_Angeles"
        })
        return pstDate.slice(0, 10)
      }

    return (
      <>
      <div className='flex justify-between items-center'>
        <div className='flex items-center'>
          <FontAwesomeIcon className='text-yellow-500 text-2xl pr-2' icon={icon}/>
          {weather.temp} &deg;F 
        </div>
        <div>
          <span className=''>{getDate()}</span>
        </div>
      </div>
        <div className='w-full my-2 border-t border-solid border-white/25'/>

      <div className='w-full text-lg flex justify-right'>

        <h4 className='text-lg xl:text-xl'>
        <span className='text-yellow-500'>
            {langContent === languages.english ? 'Status' : 'Estado'}: &nbsp;
        </span>
        {langContent.dev.status}
        </h4>
      </div>
      </>
    )
}

export default WeatherDate