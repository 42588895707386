import './App.css';
import { useState } from 'react'
import profilePic from './assets/images/profilePic.png'
import divider from './assets/images/divider.png'
import divider3 from './assets/images/divider3.png'
import NavBar from './components/NavBar';
import {languages} from './assets/content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope  } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faLinkedin,faGithub } from '@fortawesome/free-brands-svg-icons'
import Project from './components/Project';
import Contributions from './components/Contributions';
import Stacks from './components/Stacks';
import { styles } from './assets/styles';
import friver from './assets/images/Friver.png'
import Status from './components/Status';

function App() {

  const [langContent, setLangContent] = useState(languages.english)
  const [menuActive, setMenuActive] = useState(false)

  function toggleMenu() {
    setMenuActive(!menuActive)
  }

  function resetForm() {
    let inputs = document.querySelectorAll('input')
    inputs.forEach(input => {
      input.value = ''
    })
    document.querySelector('textarea').value = ''
  }

  function changeLang(chosenLang) {
    if(chosenLang) {
      setLangContent(languages.english)
    } else {
      setLangContent(languages.spanish)
    }
  }

  return (

    <div className={`wrapper bg-slate-800 font-archivo scroll-smooth`}>

      {menuActive ? 
        <div className={`w-full h-full mt-[60px] absolute bg-black/50 z-20 transition-opacity`}></div>
      :
      <></>
      }
  
      <div className=' xl:w-[1250px] lg:w-[1024px] lg:m-auto'>

        <NavBar changeLang={changeLang} lang={langContent} toggleMenu={toggleMenu} menuActive={menuActive}/>

        {/* Hero */}

        <main className='w-full h-[900px] relative pt-[70px] mb-[-150px] sm:pt-0 sm:flex sm:flex-wrap px-8'> 

        {/* weather for big screens*/}

        <Status langContent={langContent} />

        {/* pic and handles for mobile */}

          <div className='sm:w-[50%] m-auto flex justify-center items-center '>
            <div className='sm:hidden w-full absolute flex flex-wrap justify-between px-10 top-[80px]'>
              <div className='flex flex-col'>
                <a href='https://twitter.com/ErickAlonso512'><FontAwesomeIcon className='text-[28px] xs:text-[40px] relative left-20 text-white mb-12 sm:mb-0' icon={faTwitter}/></a>
                <a href='https://www.linkedin.com/in/erick-alonso?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bv%2BxtuyhOSfK47gV%2B3YbW%2Fw%3D%3D'><FontAwesomeIcon className='text-[28px] xs:text-[40px] text-white' icon={faLinkedin}/></a>
              </div>
              <div className='flex flex-col'>
                <a href='https://github.com/Erick512'><FontAwesomeIcon className='text-[28px] xs:text-[40px] relative right-20 text-white mb-12 sm:mb-0' icon={faGithub}/></a>
                <a href='mailto:alonoserick@icloud.com'><FontAwesomeIcon className='text-[28px] xs:text-[40px] text-white' icon={faEnvelope}/></a>
              </div>
            </div>

            {/* Profile pic */}

            <div className='sm:absolute w-[300px] xl:w-[350px] mt-10 sm:ml-8 xl:ml-24 bg-black/30 rounded-full shadow-2xl'>
              <img className='w-[300px] xl:w-[350px] rounded-full relative' src={profilePic} alt="" />
            </div>

            {/* Handles for big screens */}

            <div className='hidden sm:flex relative flex-col h-[300px] xl:h-[350px] justify-between items-center top-6 right-32 lg:right-[120px]'>
              <a href='https://twitter.com/ErickAlonso512'><FontAwesomeIcon className='text-[38px] xl:text-[42px]  text-white hover:text-yellow-500 hover:text-[50px] transition-all' icon={faTwitter}/></a>
              <a href='https://www.linkedin.com/in/erick-alonso?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bv%2BxtuyhOSfK47gV%2B3YbW%2Fw%3D%3D'><FontAwesomeIcon className='text-[38px] xl:text-[42px] relative right-12 text-white hover:text-yellow-500 hover:text-[50px] transition-all' icon={faLinkedin}/></a>
              <a href='https://github.com/Erick512'><FontAwesomeIcon className='text-[38px] xl:text-[42px] relative right-12 text-white hover:text-yellow-500 hover:text-[50px] transition-all' icon={faGithub}/></a>
              <a href='mailto:alonoserick@icloud.com'><FontAwesomeIcon className='text-[38px] xl:text-[42px] text-white hover:text-yellow-500 hover:text-[50px] transition-all' icon={faEnvelope}/></a>
            </div>
          </div>

          {/* Primary buttons */}

          <div className='sm:w-[50%] flex flex-col justify-center items-center'>

            <img className='w-[100%]' src={langContent.title} alt="" />  

            <div className='w-[100%] flex justify-between pt-[20px]'>
              <a href={languages.english === langContent ? '#Service' : '#Servicio'} className={ languages.english === langContent ? styles.primBtn : styles.primBtnSpanish }>{langContent.primBtns[0]}</a>
              <a href={languages.english === langContent ? '#About' : '#Sobre Mi'} className={ languages.english === langContent ? styles.primBtn : styles.primBtnSpanish }>{langContent.primBtns[1]}</a>
            </div>  
          </div>

          {/* Quote */}

          <div className='w-full'>
            <h3 className='hidden sm:block sm:w-[80%] m-auto text-center text-base sm:text-lg md:text-xl xl:text-2xl text-gray-400'>{langContent.quote}</h3>
          </div>
        </main>   

        <div>
            <img className='w-full h-[20px] rotate-180' src={divider} alt="" />
        </div>

        

        {/* About */}

        <section className='my-10 py-[60px] px-[5%]' id={langContent.links[0]}>
          <h2 className='w-full text-center text-4xl xl:text-5xl text-white mb-10'>{langContent.dev.name}</h2>

          <div className='sm:flex sm:bg-black/30 sm:py-14 rounded-2xl'>
            <div className=' w-full sm:w-[50%] sm:flex sm:flex-col sm:justify-center'>
              <h3 className='hidden sm:block w-full sm:text-center text-yellow-500 text-2xl xl:text-3xl mb-5'>{langContent === languages.english ? 'About Me' : 'Sobre Mi'}</h3>
              <p className='font-roboto font-medium w-full text-white text-lg text-center sm:text-xl md:text-2xl xl:text-[30px] xl:leading-[35px] md:text-left px-[6%] bg-black/30 py-4 rounded-xl sm:bg-inherit sm:py-0 sm:rounded-none'>{langContent.dev.description}</p>
            </div>
            <div className='w-[50%] hidden sm:flex flex-col justify-center'>
              <h3 className='w-full text-center text-white text-2xl xl:text-3xl mb-5 pr-1'>{langContent === languages.english ? 'Currently working on ' : 'Actualmente trabajando en'} <span className='text-yellow-500'>Friver</span></h3>
              <img className='w-[80%] m-auto rounded-2xl shadow-2xl shadow-black' src={friver} alt="" />
            </div>
          </div>
            
        </section>  

        <div>
          <img className='w-full h-[20px]' src={divider} alt="" />
        </div> 

        {/* Projects */}

        <section className='my-40 pt-[60px] pb-[100px] bg-slate-900 rounded-lg' id={langContent.links[1]}>

          <h2 className='w-full text-center text-4xl sm:text-5xl text-white mb-[-40px] md:mb-32'>{langContent === languages.english ? 'Projects' : 'Proyectos'}</h2>
          

          {langContent.projects.map((project, idx) => {
            return (
              <>
                <Project key={idx} project={project} />

                {idx === langContent.projects.length - 1 ? 
                
                  <></>
                :
                  // <div className='my-20 md:my-52 h-[2px] w-[85%] m-auto border border-dashed border-yellow-500'/>
                  <img  className='w-full my-20 sm:my-40 ' src={divider3} alt=''/>
                }

              </>
            )

          })}

        <div>
          <img className='mb-20 md:my-40 mt-20 w-full h-[20px]' src={divider} alt="" />
        </div>

          
          {/* Contributions */}

          <h2 className='w-full text-center text-4xl sm:text-5xl text-white mb-[-40px] md:mb-[150px]'>{langContent === languages.english ? 'Contributions' : 'Contribuciones'}</h2>

          {langContent.contributions.map( (contribution, idx) => {
            return (
              <>
                <Contributions key={idx} project={contribution} />
              </>
            )
          })}

        </section>  

        <div>
          <img className='mb-10 md:mb-40 w-full h-[20px]' src={divider} alt="" />
        </div>

        {/* Stacks */}

        <section className='py-10 px-[6%]' id={langContent.links[2]}>
        
          <h2 className='w-full text-center text-4xl sm:text-5xl text-white mb-5'>{langContent === languages.english ? 'Stacks' : 'Marcos Tecnológicos'}</h2>

          <ul className='p-[20px] flex flex-wrap justify-center'>
            {langContent.stacks.map( (stack , idx) => {
              return <Stacks key={idx} name={stack.name} icon={stack.icon}/>
            })}
          </ul>
        
        </section> 

        <div>
          <img className='mb-10 sm:mb-20 w-full h-[20px]' src={divider} alt="" />
        </div>

        {/* Service */}

        <section className='py-10 px-[6%]' id={langContent.links[3]}>

        <h2 className='w-full text-center text-4xl sm:text-5xl text-white mb-8'>{langContent === languages.english ? 'Service' : 'Servicio'}</h2>

        <ul className='w-full flex flex-wrap justify-between'>

          {langContent.services.map( (service, idx) => {
            return (
              <>
              <i className='flex text-white mb-10 bg-black/30 py-3 px-2 rounded-xl sm:w-[47%]' key={idx}>
                
                <FontAwesomeIcon className='text-3xl text-yellow-500 mt-4 mr-4 ml-2' icon={service.icon}/>

                <div className='pr-2'>
                  <h3 className='text-xl md:text-2xl xl:text-3xl my-4'>{service.name}</h3>
                  <p className='text-lg md:text-xl xl:text-2xl pb-4'>{service.desc}</p>
                </div>

              </i>
              </>
            )
          })}
        </ul>

          {/* Examples */}

            {/* <h2 className='width-full text-center text-3xl underline text-white'>{langContent === languages.english ? 'Websites Examples': 'Sitios de Ejemplos'}</h2>
              <div className='sm:width-full md:flex md:justify-between mt-16 text-xl text-black'>
                {langContent.examples.map( (example, idx) => {
                  return(
                    <>
                      <h3 className='md:w-[23%] w-[75%] text-center border border-solid border-black py-4 m-auto mb-5 rounded-lg bg-yellow-500 '>{example.name}</h3>
                    </>
                  )

                })}
              </div> */}

        </section>

        <div>
          <img className='mb-10 mt-10 sm:mb-20 w-full h-[20px]' src={divider} alt="" />
        </div>

        {/* Contact */}

        <form className='max-w-[700px] px-[6%] sm:px-0 m-auto sm:flex sm:flex-wrap justify-between mb-20' id={langContent.links[4]}
          name="contact" netlify netlify-honeypot="bot-field"
        >

          <h2 className='w-full text-center text-3xl sm:text-5xl text-white mb-3 sm:mb-6'>{langContent === languages.english ? 'Contact Me' : 'Contáctame'}</h2>

          <h4 className='w-full mb-10 sm:mb-10 text-sm sm:text-lg text-gray-500'>
            {langContent === languages.english ? 'Send me an email at ' : 'Mandame un email a '}
            <a href='mailto:alonsoerick@icloud.com' className='text-gray-400 cursor-pointer underline'>alonoserick@icloud.com</a>
            {langContent === languages.english ? " or write me a message below. I'm currenly accepting new project opportunities." : " o escríbeme un mensaje abajo. Actualmente estoy aceptando nuevas oportunidades de proyectos."}
            </h4>

          <input type="hidden" name="form-neame" value="the-name-of-the-html-form" />

          <div className='sm:w-[300px]'>
            <h3 className='text-yellow-500 text-[20px] xl:text-3xl mb-3 xl:mb-5'>{langContent === languages.english ? 'Name' : 'Nombre'}</h3>
            <input className='w-full h-12 mb-6 rounded-lg px-3  caret-yellow-500 bg-black/30 text-white outline outline-2 outline-gray-600 focus:outline-gray-500' type="text" name='form-name'/>
          </div>

          <div className='sm:w-[300px]'>
            <h3 className='text-yellow-500 text-[20px] xl:text-3xl mb-3 xl:mb-5'>Email</h3>
            <input className='w-full h-12 mb-6 rounded-lg px-3  caret-yellow-500 bg-black/30 text-white outline outline-2 outline-gray-600 focus:outline-gray-500' type="email" name='form-email' />
          </div>

          <h3 className='w-full text-yellow-500 text-[20px] xl:text-3xl mb-3 xl:mb-5 sm:mt-6'>{langContent === languages.english ? 'Message' : 'Mensaje'}</h3>
          <textarea className='w-full rounded-lg p-3 leading-5 mb-6 caret-yellow-500 bg-black/30 text-white outline outline-2 outline-gray-600 focus:outline-gray-500' type="text" rows={8} name='form-message'/>

          <div className='w-full flex justify-between sm:mt-6 mb-4'>
            <button type='submit' className='sm:w-[200px] mini:w-[40%] xl:text-2xl xs:text-xl mini:text-base font-bold> bg-yellow-500 py-2 rounded-xl hover:animate-pulse shadow-2xl'>{langContent === languages.english ? 'Submit' : 'Enviar'}</button>
            <button onClick={() => resetForm()} type='button' className='sm:w-[200px] mini:w-[40%] xl:text-2xl xs:text-xl mini:text-base font-bold> bg-yellow-500 py-2 rounded-xl hover:animate-pulse shadow-2xl'>{langContent === languages.english ? 'Reset' : 'Reiniciar'}</button>
          </div>
        </form>

        <footer className='w-full flex justify-center items-center h-[100px] bg-black/30 text-[10px] text-white p-6 lg:rounded-t-xl'>
            <span className='w-full text-center'>
              © ErickAlonso. All rights reserved 
              <i className='h-full border border-solid border-white mx-2 leading-6'></i>
              Design: Erick Alonso
            </span>
        </footer>
      </div>


    </div>
  )
}

export default App;