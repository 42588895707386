import {React, useEffect, useState } from 'react'
import { faCloudMoon, faCloudSun , faSun, faMoon, faCloud, faWind, faSmog, faCloudShowersHeavy, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import WeatherDate from './WeatherDate';


const Status = ({langContent}) => {

    const weatherApi = 'https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/California,Inglewood?key=5JJ65CFHPWWRJKCA97VGJPGAP'
    const [weather, setWeatehr] = useState({})

    useEffect(() => {
        fetch(weatherApi)
        .then(res => res.json())
        .then(data => {
          setWeatehr(data.currentConditions)
        })
    }, [])

    function getWeatherIcon(weatherIcon) {
        switch(weatherIcon){
          case 'snow':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faSnowflake} />) 
          case 'rain':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faCloudShowersHeavy} />) 
          case 'fog':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faSmog} />) 
          case 'wind':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faWind} />) 
          case 'cloudy':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faCloud} />) 
          case 'partly-cloudy-day':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faCloudSun} />)  
          case 'partly-cloudy-night':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faCloudMoon} />) 
          case 'clear-day':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faSun} />)  
          case 'clear-night':
            return (<WeatherDate langContent={langContent} weather={weather} icon={faMoon}/>) 
          default: <></>
    
        }
    }

    return (
        <div className='hidden w-[330px] sm:block absolute right-5 top-5 xl:right-0 bg-black/30 px-4 py-2 text-white text-base xl:text-lg rounded-xl shadow-xl'>{getWeatherIcon(weather.icon)}</div>
    )
}

export default Status