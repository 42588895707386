import Friver from './images/Friver.png'
import portfolio from './images/portfolio.png'
import rjs from './images/rjs.png'
import billtrakr from './images/billtrakr.png'
import pokerHands from './images/pokerHandsSized.png'
import laxbarber from './images/laxbarber.png'
import { faHandshakeSimple, faPeopleArrows, faCode, faRocket} from '@fortawesome/free-solid-svg-icons'
import { faBootstrap, faCss3, faHtml5, faJs, faNodeJs, faReact, faSass } from '@fortawesome/free-brands-svg-icons'
import spanishTitlePic from './images/spanishTitle2.png'
import titlePic from './images/title.png'

export const languages = {
    english: {

        title: titlePic,

        quote: '"Have the courage to follow your heart and intuition. They somehow know what you truly want to become." - Steve Jobs',

        primBtns: [
            'I need a Website',
            'I need a Developer'
        ],

        dev: {
            name: 'Erick Alonso',
            status: 'Open to work',
            email: 'alonsoerick@icloud.com',
            number: '(323)-682-3589',
            description: "I am a software engineer based in Los Angeles, California. I fell in love with web development when I had the opportunity to create a website for a construction company that I worked for. Since then I've worked hard to pursue this career. I've adapted and worked with many frameworks that allowed me to create fast, responsive and optimized websites."

        },

        links: ['About', 'Projects', 'Stacks','Service', 'Contact'],
        
        projects: [
            {
                title: 'Friver',
                url: 'https://friver.herokuapp.com',
                img: Friver,
                description: "is a platform for the rideshare drivers. Drivers are able to communicate and stay up to date with others through the platform. Drivers are also able to notify others when surges appear.",
                stacks: ['Html', 'Css', 'Js', 'Node.js', 'Express.js', 'MongoDB', 'Passport.js']
            },
            {
                title: 'Portfolio',
                url: 'https://erickalonso.com',
                img: portfolio,
                description: 'My personal website where I showcase my current work, servivce and availability.',
                stacks: ['Html', 'Css', 'Js', 'React', 'Tailwind', 'Weather and date api']
            },
            {
                title: 'Lax Barber Salon',
                url: 'https://laxbarbersalon.netlify.app',
                img: laxbarber,
                description: 'A Website made for a barber slaon near LAX. The website helped the establishment gain clients from incoming tourists.',
                stacks: ['Html', 'Css', 'TailwindCSS']
            },
            {
                title: "Rj's Tile & Stone",
                url: 'https://alontileandstone.netlify.app',
                img: rjs,
                description: 'A modern and responsive website built for a company which doubled thier clientele.',
                stacks: ['Html', 'Css', 'Js', 'Sass']
            },
            {
                title: 'Poker Hands',
                url: 'https://playpokerhands.netlify.app',
                img: pokerHands,
                description: 'A game where you can practice playing the best poker hands for the river within a time limit.',
                stacks: ['Html', 'Css', 'Js', 'Deck of cards api']
            },
        ],
        
        contributions: [
            {
                title: 'Billtrakr',
                url: 'https://bill-trackr.herokuapp.com',
                img: billtrakr,
                description: 'A simple way to privately track your bills. Users are able to create a password protected account where they have their own personalized dahsboard of thier current bills.',
                stacks: ['Html', 'Css', 'Js', 'React', 'MongoDB', 'Express.js', 'Node.js', 'Passpport.js']
            }
        ],
        
        stacks: [
            {
                name: 'HTML',
                icon: faHtml5
            },
            {
                name: 'CSS3',
                icon: faCss3
            },
            {
                name: 'Javascript',
                icon: faJs
            },
            {
                name: 'Bootstrap',
                icon: faBootstrap
            },
            {
                name: 'Sass',
                icon: faSass
            },
            {
                name: 'React',
                icon: faReact
            },
            {
                name: 'Node.js',
                icon: faNodeJs
            },
        ],

        services: [
            {
                name: '1. Consultation',
                icon: faPeopleArrows,
                desc: 'To start things off, we talk about your dream website and how we can make it come to life.'
            },
            {
                name: '2. Proposal',
                icon: faHandshakeSimple,
                desc: 'Next, I take in all the details of your dream website and write you a proposal that meets your budget.'
            },
            {
                name: '3. Creation',
                icon: faCode,
                desc: 'Once we both come to an agreement, we sign a contract and I start working on making your dream website a reality.'
            },
            {
                name: '4. Launch & support',
                icon: faRocket,
                desc: "Your website goes live! We can also discuss maintenance or features you'd like to add later if needed."
            },
        ],
        examples: [
            {
                name: 'Restaurant',
            },
            {
                name: 'Barbershop'
            },
            {
                name: 'Perosnal Website'
            },
            {
                name: 'Blogging Page'
            }
        ]
    },

    /**** Spanish *****/ 

    spanish: {

        title: spanishTitlePic,

        quote: '"Ten el valor de seguir tu corazón y tu intuición. De alguna manera saben en lo que realmente quieres convertirte." - Steve Jobs',

        primBtns: [
            'Necesito una pagina',
            'Necesito un Ingeniero'
        ],

        dev: {
            name: 'Erick Alonso',
            status: 'Abierto al trabajo',
            email: 'alonsoerick@icloud.com',
            number: '(323)-682-3589',
            description: "Soy un ingeniero de software con sede en Los Ángeles, California. Me enamoré del desarrollo de sitios web cuando tuve la oportunidad de crear un sitio web para una empresa de construcción en la que trabajaba. Desde entonces he trabajado duro para seguir esta carrera. Me adapté y trabajé con muchos marcos que me permitieron crear sitios rápidos, receptivos y optimizados."
        },

        links: ['Sobre Mi', 'Projectos', 'Marcos', 'Servicio', 'Contáctame'],
        
        projects: [
            {
                title: 'Friver',
                url: 'https://friver.herokuapp.com',
                img: Friver,
                description: "Es una plataforma para conductores de viajes compartidos. Los conductores pueden comunicarse a través de la plataforma. Los conductores también pueden notificar a otros cuando aparecen sobretensiones.",
                stacks: ['Html', 'Css', 'Js', 'Node.js', 'Express.js', 'MongoDB', 'Passport.js']
            },
            {
                title: 'Portfolio',
                url: 'https://erickalonso.com',
                img: portfolio,
                description: 'Mi sitio web personal donde muestro mi trabajo actual y brindo servicios a clientes potenciales.',
                stacks: ['Html', 'Css', 'Js', 'React', 'Tailwind', 'Weather and date api']
            },
            {
                title: "Rj's Tile & Stone",
                url: 'https://alontileandstone.netlify.app',
                img: rjs,
                description: "Un sitio web moderno y receptivo creado para una empresa que duplicó su clientela. Los clientes pueden ver el trabajo actual de la empresa y recibir consultas.",
                stacks: ['Html', 'Css', 'Js', 'Sass']
            },
            {
                title: 'Poker Hands',
                url: 'https://playpokerhands.netlify.app',
                img: pokerHands,
                description: 'Un juego donde podrás practicar jugando las mejores manos de poker para el river dentro de un tiempo límite.',
                stacks: ['Html', 'Css', 'Js', 'Deck of cards api']
            }
        ],
        
        contributions: [
            {
                title: 'Billtrakr',
                url: 'https://bill-trackr.herokuapp.com',
                img: billtrakr,
                description: 'Una forma sencilla de realizar un seguimiento privado de sus facturas. Los usuarios pueden crear una cuenta protegida con contraseña donde tienen su tablero personalizado de sus facturas actuales.',
                stacks: ['Html', 'Css', 'Js', 'React', 'MongoDB', 'Express.js', 'Node.js', 'Passpport.js']
            }
        ],
        
        stacks: [
            {
                name: 'HTML',
                icon: faHtml5
            },
            {
                name: 'CSS3',
                icon: faCss3
            },
            {
                name: 'Javascript',
                icon: faJs
            },
            {
                name: 'Bootstrap',
                icon: faBootstrap
            },
            {
                name: 'Sass',
                icon: faSass
            },
            {
                name: 'React',
                icon: faReact
            },
            {
                name: 'Node.js',
                icon: faNodeJs
            },
        ],
        services: [
            {
                name: '1. Consulta',
                icon: faPeopleArrows,
                desc: 'Para comenzar, hablamos sobre el sitio web de sus sueños y cómo podemos hacerlo realidad.'
            },
            {
                name: '2. Propuesta',
                icon: faHandshakeSimple,
                desc: 'A continuación, analizo todos los detalles del sitio web de tus sueños y te escribo una propuesta que se ajuste a tu presupuesto.'
            },
            {
                name: '3. Creación',
                icon: faCode,
                desc: 'Una vez que ambos llegamos a un acuerdo, firmamos un contrato y comenzamos a trabajar para hacer realidad el sitio web de sus sueños.'
            },
            {
                name: '4. Lanzamiento y soporte',
                icon: faRocket,
                desc: "¡Tu sitio web se activa! También podemos analizar el mantenimiento o las funciones que le gustaría agregar más adelante si es necesario."
            },
        ],
        examples: [
            {
                name: 'Restaurante'
            },
            {
                name: 'Peluquería'
            },
            {
                name: 'Pagina Personal'
            },
            {
                name: 'Pagine de blogs'
            }
        ]
    }
}
