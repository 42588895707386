import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Contributions = ({project}) => {
  return (
    <div className='h-full px-[6%] sm:flex sm:justify-bewteen'>

      <img className='hidden sm:block relative z-10 w-[340px] md:h-[300px] m-auto border sm:w-[400px] sm:h-[300px] rounded-lg mr-2 shadow-2xl ' src={project.img} alt="" />

      <div className='h-325px  md:h-[300px] flex flex-col px-[20px] relative md:pr-16 xl:pr-1 mini:mt-[200px] pt-[140px] bg-black/30 py-6 rounded-3xl md:mt-0 md:bg-inherit md:py-2'>

          <a href={`${project.url}`} className='w-full sm:hidden cursor-pointer absolute top-[-100px] left-0'>
            <img  className='relative z-10 w-[280px] xs:w-[300px]  md:h-[300px] m-auto border sm:w-[400px] sm:h-[300px] shadow-2xl rounded-md' src={project.img} alt="" />
          </a>

          <p className='mb-10 w-full text-left text-lg md:text-xl xl:text-2xl text-white sm:my-0 z-10 pr-4'>
            <span className='text-[22px] md:text-3xl xl:text-4xl text-yellow-500 '>
             {project.title} &nbsp;
            </span>
             {project.description}
          </p>

          <div className='h-full flex items-end z-10 pr-3'>

            <ul className='text-white leading-6'>
              {project.stacks.map((stack, idx) => {
                  return <i key={idx} className='text-yellow-500 md:text-xl xl:text-2xl'>{stack} {idx === project.stacks.length - 1 ? '' : ' • '} </i>
              })}
            </ul>

          </div>

          <div className='hidden sm:block h-[400px] xl:h-[430px] md:w-[800px] xl:w-[1040px]  absolute bg-black/30 bottom-[-50px] xl:bottom-[-65px] left-[-300px] rounded-full' />
          {/* <div className='hidden sm:flex absolute h-[100px] w-[130px] bg-black/30 bottom-[-30px] left-[-390px] flex-col justify-end rounded-b-xl hover:bottom-[-70px] transition-all'>
            <a href={`${project.url}`} className='w-full text-yellow-500 text-sm py-1 cursor-pointer text-center' >Check it out &nbsp;
            </a>
            <FontAwesomeIcon className='w-full text-yellow-500 text-xl pb-2' icon={faChevronDown} />
          </div>   */}
        </div>

    </div>
  )
}

export default Contributions